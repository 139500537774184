import decode from 'entity-decode';
import prettyNum, {PRECISION_SETTING, ROUNDING_MODE} from 'pretty-num';
// import stripZeros from 'pretty-num/src/strip-zeros';
// import fromExponential from 'from-exponential';
// import parseISO from "date-fns/esm/parseISO";
// import format from "date-fns/esm/format";
// import formatDistanceStrict from "date-fns/esm/formatDistanceStrict";
// // import formatDuration from "date-fns/esm/formatDuration";
// // import intervalToDuration from "date-fns/esm/intervalToDuration";
// import dateFnsRuLocale from 'date-fns/esm/locale/ru/index.js';


/**
 *
 * @param {string|number} value
 * @param {number} precision
 * @param {PRECISION_SETTING} [precisionSetting]
 * @return {string}
 */
export function prettyNumber(value, precision, precisionSetting) {
    return decode(prettyNum(value, {
        precision,
        precisionSetting,
        roundingMode: ROUNDING_MODE.FLOOR,
        separateOneDigit: false,
        thousandsSeparator: '&#x202F;',
    }));
}

/**
 * @param {string|number} value
 * @return {string}
 */
export function pretty(value) {
    if (!value && value !== 0) {
        return '';
    }
    const PRECISION_FIXED = 2;
    const PRECISION = 3;
    if (+value > 0.3 || +value <= -0.3 || Number(value) === 0) {
        return prettyNumber(value, PRECISION_FIXED, PRECISION_SETTING.FIXED);
    } else {
        value = prettyNumber(value, PRECISION, PRECISION_SETTING.REDUCE_SIGNIFICANT);
        value = value.substr(0, 10);
        if (value === '0.00000000') {
            return '0.00';
        }
        return value;
    }
}

/**
 * @param {string|number} value
 * @return {string}
 */
export function prettyUsd(value) {
    return prettyNumber(value, 2, PRECISION_SETTING.FIXED);
}

export function prettyRound(value) {
    return prettyNumber(value, 0, PRECISION_SETTING.FIXED);
}
